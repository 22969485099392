'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { FC, useEffect, useState } from 'react';

export interface OptimizelyEventsProps {
  type: string;
}

export const OptimizelyEvents: FC<OptimizelyEventsProps> = ({ type }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (hasLoaded) {
      window.optimizely = window.optimizely || [];
      window.optimizely.push({
        type: type,
      });
    } else {
      setHasLoaded(true); // Avoid running on initial load
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  return null;
};
